import React, { useState } from 'react'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import {
  thetaIcon,
  thetaMin,
  thetaStep,
  thetaUnit,
  thetaDecimalScale,
  getThetaPhiPhaseWarningText,
} from 'src/constants/beamformers'
import { rangeText } from 'src/containers/operating/device/beamformers/style'
import InputGroup from 'src/components/Input/InputGroup'

const Theta = props => {
  const {
    // Required
    value = '0',
    thetaMax = 60,
    onChange = () => {},

    // input props
    className = 'w-full',
    icon = thetaIcon,
    unit = thetaUnit,
    step = thetaStep,
    loop = false,
    validMin = thetaMin,
    validMax = thetaMax,
    decimalScale = thetaDecimalScale,
    off = false,
    size = 'lg',

    // dialog props
    placement = 'top',
    overwriteOffset = { top: 0, left: 0 },
    onFocus = () => {},
  } = props

  const [warning, setWarning] = useState('')

  const thetaInputProps = {
    size,
    icon,
    unit,
    step,
    loop,
    validMin,
    validMax,
    keydownMin: validMin,
    keydownMax: validMax,
    decimalScale,
    value,
    off,
  }

  // dialog 要蓋上去的元素
  const overwriteElements = (
    <div>
      <InputGroup warning={true} disabled={true} {...thetaInputProps} />
      <span className={rangeText + ' text-yellow'}>Max {thetaMax}</span>
    </div>
  )
  const warningText = getThetaPhiPhaseWarningText({
    min: thetaMin,
    max: thetaMax,
    step: thetaStep,
  })
  const thetaDialogProps = {
    placement,
    overwriteOffset,
    overwriteElements: overwriteElements,
    dialogText: warningText[warning],
    warning: warning,
    setWarning: result => setWarning(result),
    setFocus: onFocus,
    changeCallback: onChange,
  }

  return (
    <div className={className}>
      <InputGroupAppendDialog {...thetaInputProps} {...thetaDialogProps} />
      <span className={rangeText}>Max {thetaMax}</span>
    </div>
  )
}

export default Theta
