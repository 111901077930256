import React from 'react'
import PropTypes from 'prop-types'

import { getBeamIndex, isIncident, isReflection } from 'src/funcs/device/ris'

import Theta from 'src/components/composite/InputGroupAppendDialog/Theta'
import Phi from 'src/components/composite/InputGroupAppendDialog/Phi'

const InputControl = ({
  deviceType,
  targetBeam,
  thetaI,
  phiI,
  thetaMaxI,
  thetaR,
  phiR,
  thetaMaxR,

  handleInputChange,
  handleInputFocus,
}) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const _getTheta = targetBeam => {
    if (isIncident(targetBeam)) return thetaI[getBeamIndex(targetBeam)]
    if (isReflection(targetBeam)) return thetaR[getBeamIndex(targetBeam)]
  }
  const _getThetaMax = targetBeam => {
    if (isIncident(targetBeam)) return thetaMaxI[getBeamIndex(targetBeam)]
    if (isReflection(targetBeam)) return thetaMaxR[getBeamIndex(targetBeam)]
  }

  const iClassProps = index => ({
    isActive: isIncident(targetBeam),
  })
  const rClassProps = index => ({
    isActive: targetBeam === `reflection_${index}`,
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className={container}>
      <div className='flex gap-x-4 '>
        {/* Incident */}
        {/* Incident */}
        {/* Incident */}
        {thetaI.map((e, i, a) => (
          <div
            key={`beam steering input incident ${i}`}
            className='flex flex-col gap-y-3'
            onClick={() => handleInputFocus({ targetBeam: `incident_${i}` })}>
            <h6 className={subtitle(iClassProps(i))}>
              Incident {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
            </h6>

            <div className={itemBox(iClassProps(i))}>
              <Theta
                value={String(_getTheta(`incident_${i}`)) || ''}
                thetaMax={_getThetaMax(`incident_${i}`)}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'theta',
                    targetBeam: `incident_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>

            <div className={itemBox(iClassProps(i))}>
              <Phi
                value={+phiI[i]}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'phi',
                    targetBeam: `incident_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>
          </div>
        ))}

        {/* Reflection */}
        {/* Reflection */}
        {/* Reflection */}
        {thetaR.map((e, i, a) => (
          <div
            key={`beam steering input reflection ${i}`}
            className='flex flex-col gap-y-3'
            onClick={() => handleInputFocus({ targetBeam: `reflection_${i}` })}>
            <h6 className={subtitle(rClassProps(i))}>
              Reflection{' '}
              {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
            </h6>

            <div className={itemBox(rClassProps(i))}>
              <Theta
                value={String(_getTheta(`reflection_${i}`)) || ''}
                thetaMax={_getThetaMax(`reflection_${i}`)}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'theta',
                    targetBeam: `reflection_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>

            <div className={itemBox(rClassProps(i))}>
              <Phi
                value={+phiR[i]}
                size='lg'
                onChange={({ value, isValid }) =>
                  handleInputChange({
                    label: 'phi',
                    targetBeam: `reflection_${i}`,
                    value,
                    isValid,
                  })
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InputControl

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `[BeamSteering-InputControl-container]
                    relative pt-7 flex flex-col items-center
                    `

const subtitle = ({ isActive, isOff }) =>
  `${!isOff && isActive ? 'text-teal' : 'text-light-5'} 
                                font-sm font-bold text-center duration-500`
const itemBox = ({ isActive, isOff }) => `
                                ${
                                  !isOff && isActive
                                    ? 'opacity-100 drop-shadow-md'
                                    : 'opacity-50 drop-shadow-2xl'
                                } 
                                flex flex-col w-full 
                                duration-500
                                `

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type  validate   -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputControl.propTypes = {
  theta: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  phi: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  handleInputChange: PropTypes.func,
}
