import React, { useState } from 'react'

import InputControl from 'src/components/composite/beamformers/BeamSteeringModule/ForRIS/InputControl'
import Beam3DButtons from 'src/components/composite/beamformers/BeamSteeringModule/ForRIS/Beam3DButtons'

import Beam3DControlForRIS from 'src/components/composite/beamformers/Beam3DControl/ForRIS'

import ErrorBoundaryOfThree from 'src/containers/ErrorBoundary/Three'
import { getBeamIndex, isIncident } from 'src/funcs/device/ris'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'
import { getThetaPhiPhaseWarningText } from 'src/constants/beamformers'
import InputGroup from 'src/components/Input/InputGroup'

import { BiMoveHorizontal } from 'react-icons/bi'

const BeamSteeringModule = props => {
  const {
    sn,
    isDontRender3D,
    deviceType,
    targetBeam,
    handleTargetBeamChange,
    // value
    thetaI,
    thetaR,
    phiI,
    phiR,
    thetaMaxI,
    thetaMaxR,
    distance,
    distanceMin,
    distanceMax,

    // 3d control event
    handleDrag,
    handleDragEnd,
    beam3DCameraArgs,
    handleCameraChange,

    // 3d button event
    deviceDirection,
    handleDirectionChange,
    handleHelpButtonClick,
    handleBoresightClick,

    // input event
    handleInputChange,
    handleInputFocus,
    handleDistanceChange,
  } = props

  // 按 reset 鏡頭的按鈕會設成 true, 100ms 後自動設回 false
  const [isCameraReset, setIsCameraReset] = useState(false)

  const isIncidentBoresightDisabled =
    +thetaI[getBeamIndex(targetBeam)] === 0 &&
    +phiI[getBeamIndex(targetBeam)] === 0
  const isReflectionBoresightDisabled =
    +thetaR[getBeamIndex(targetBeam)] === 0 &&
    +phiR[getBeamIndex(targetBeam)] === 0

  const boresightDisabled = isIncident(targetBeam)
    ? isIncidentBoresightDisabled
    : isReflectionBoresightDisabled

  const [warning, setWarning] = useState('')

  const distanceInputProps = {
    size: 'md',
    icon: <BiMoveHorizontal className='text-light-4 text-xl' />,
    unit: 'm',
    step: 0.01,
    loop: false,
    validMin: distanceMin[0],
    validMax: distanceMax[0],
    keydownMin: distanceMin[0],
    keydownMax: distanceMax[0],
    decimalScale: 2,
    value: distance[0],
  }

  const overwriteElements = (
    <div>
      <InputGroup warning={true} disabled={true} {...distanceInputProps} />
      <span className={`${rangeText} text-yellow mt-2`}>
        Range {distanceMin} ~ {distanceMax}m
      </span>
    </div>
  )
  const warningText = getThetaPhiPhaseWarningText({
    min: distanceMin[0],
    max: distanceMax[0],
    step: 0.01,
  })

  const distanceDialogProps = {
    placement: 'top',
    overwriteOffset: { top: 0, left: 0 },
    overwriteElements: overwriteElements,
    dialogText: warningText[warning],
    warning: warning,
    setWarning: result => setWarning(result),
    setFocus: () => handleInputFocus({ targetBeam: `incident_0` }),
    changeCallback: ({ value, isValid }) =>
      handleDistanceChange({ value, index: 0, isValid }),
  }

  const handleCameraReset = value => setIsCameraReset(value)
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <>
      <div className='bg-light-4/10 px-4 py-2 rounded mb-4 flex flex-col gap-y-1'>
        <h6 className='font-sm text-light-1'>Incident distance to RIS</h6>
        <InputGroupAppendDialog
          {...distanceInputProps}
          {...distanceDialogProps}
        />
        <span className={`${rangeText} text-white/50`}>
          Range {distanceMin} ~ {distanceMax}m
        </span>
      </div>

      <div className={container}>
        <div className='relative w-full h-[460px]'>
          <ErrorBoundaryOfThree>
            {!isDontRender3D && (
              <Beam3DControlForRIS
                {...{
                  sn,
                  deviceType,
                  targetBeam,
                  handleTargetBeamChange,
                  thetaI,
                  thetaR,
                  phiI,
                  phiR,
                  thetaMaxI,
                  thetaMaxR,
                  distance,
                  distanceMin,
                  distanceMax,
                  handleDrag,
                  handleDragEnd,
                  beam3DCameraArgs,
                  handleCameraChange,
                  isCameraReset,
                  handleCameraReset,
                  deviceDirection,
                }}
              />
            )}
            <Beam3DButtons
              {...{
                deviceDirection,
                handleDirectionChange,
                handleCameraReset,
                handleHelpButtonClick,
                handleBoresightClick,
                boresightDisabled,
              }}
            />
          </ErrorBoundaryOfThree>
        </div>

        <InputControl
          {...{
            deviceType,
            targetBeam,
            thetaI,
            thetaR,
            phiI,
            phiR,
            thetaMaxI,
            thetaMaxR,
            distance,
            distanceMin,
            distanceMax,
            handleInputChange,
            handleInputFocus,
          }}
        />
      </div>
    </>
  )
}

export default BeamSteeringModule

const container = `bg-light-4/10 
                    flex flex-col 
                    gap-y-4 
                    rounded-lg 
                    px-5 md:px-5 xl:px-4
                    pb-10
                    `

export const rangeText = `block text-xs font-normal mt-1`
