import { useErrorBoundary } from 'react-error-boundary'
import { _apiPkg } from 'src/redux/sagas/services/restfulTools/__restful_API_sender'
//!                           筆記 一
//!   =======================================================!
//!   =======================================================!
//!             FormData 不可序列化，進 redux 會跳警告
//!     雖然可以關掉警告，但 redux 一開始設計就是不處理不可序列化得值
//!                      關掉不算是一件好事
//!                 因此特別拉一個 hook 出來處理
//!   =======================================================!
//!   =======================================================!

//!                           筆記 二
//!   =======================================================!
//!   =======================================================!
//!           可以把撈出來的 event.target.files 的 Blob
//!  轉成 ArrayBuffer：透過 FilerReader() 來轉成 ArrayBuffer 的格式
//!           轉成 Uint8Array：接著透過 new Uint8Array()
//!                 把這個 ArrayBuffer 轉成類陣列
//!                 再用 Array.from() 轉成真陣列
//!          然後就可以透過 action.payload 送進 redux-saga
//!             但如果檔案太大，上面流程會走很久讓上傳非常慢
//!       目前檔案上傳需要上傳 calibration table 一張大概 50mb
//!         走這個流程會非常久，因此最後還是使用 hook 直接送後端
//!   =======================================================!
//!   =======================================================!

const useUploadFiles = ({
  inputFileRef,
  api,
  payload,
  beforeCallback,
  afterCallback,
}) => {
  const { showBoundary } = useErrorBoundary()

  const handleFileInputChange = event => {
    beforeCallback(event)

    return new Promise((res, rej) => {
      res(event.target.files)
    })
      .then(files => {
        const formData = new FormData()
        formData.append('payload', payload)

        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i])
        }

        console.time('files upload:')
        return formData
      })
      .then(formData => _apiPkg(api(formData)))
      .then(response => {
        console.timeEnd('files upload:')

        // 這是 ajax, 要撈 data 的話需要 response.data.data
        // 這邊先開一層 .data, 外面需要第二層的 code 判斷成功失敗
        const resData = response?.data

        afterCallback(resData)
      })
      .catch(error => {
        console.warn(error)
        showBoundary(error)
      })
      .finally(() => {
        inputFileRef.current.value = null
      })
  }

  return { handleFileInputChange }
}

export default useUploadFiles
