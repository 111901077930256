import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'
import { commonAfterImport } from 'src/redux/sagas/handlers/event/beamformers/ris/common'

import { steeringThetaPhi as handleSteeringThetaPhiResponse } from 'src/redux/sagas/handlers/response/beamformers/ris/steering'

const risApi = {
  RIS_STEERING_CHANGE: {
    emit: {
      server: 'beamformers',
      eventName: 'ris/beam/steering/thetaPhiDistance',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'ris/beam/steering/thetaPhiDistance/response',
      handler: handleSteeringThetaPhiResponse,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  RIS_PATTERN_IMPORT: {
    emit: {
      server: 'beamformers',
      eventName: 'ris/pattern/import',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'ris/pattern/import/response',
      handler: commonAfterImport,
      errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default risApi
