import React, { useState } from 'react'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import {
  phiIcon,
  phiMin,
  phiMax,
  phiStep,
  phiUnit,
  phiDecimalScale,
  getThetaPhiPhaseWarningText,
} from 'src/constants/beamformers'
import { rangeText } from 'src/containers/operating/device/beamformers/style'
import InputGroup from 'src/components/Input/InputGroup'

const Phi = props => {
  const {
    // Required
    value = '0',
    onChange = () => {},

    // input props
    className = 'w-full',
    icon = phiIcon,
    unit = phiUnit,
    step = phiStep,
    loop = true,
    validMin = phiMin,
    validMax = phiMax,
    decimalScale = phiDecimalScale,
    off = false,
    size = 'lg',

    // dialog props
    placement = 'top',
    overwriteOffset = { top: 0, left: 0 },
    onFocus = () => {},
  } = props

  const [warning, setWarning] = useState('')

  const phiInputProps = {
    size,
    icon,
    unit,
    step,
    loop,
    validMin,
    validMax,
    keydownMin: validMin,
    keydownMax: validMax,
    decimalScale,
    value,
    off,
  }

  // dialog 要蓋上去的元素
  const overwriteElements = (
    <div>
      <InputGroup warning={true} disabled={true} {...phiInputProps} />
      <span className={rangeText + ' text-yellow'}>Max {phiMax}</span>
    </div>
  )
  const warningText = getThetaPhiPhaseWarningText({
    min: phiMin,
    max: phiMax,
    step: phiStep,
  })
  const phiDialogProps = {
    placement,
    overwriteOffset,
    overwriteElements: overwriteElements,
    dialogText: warningText[warning],
    warning: warning,
    setWarning: result => setWarning(result),
    setFocus: onFocus,
    changeCallback: onChange,
  }

  return (
    <div className={className}>
      <InputGroupAppendDialog {...phiInputProps} {...phiDialogProps} />
      <span className={rangeText}>Max {phiMax}</span>
    </div>
  )
}

export default Phi
