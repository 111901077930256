import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Mask from 'src/components/Mask'

import InstructionMask from 'src/components/composite/beamformers/BeamSteeringModule/ForRIS/InstructionMask'

import { ris_steering_importModeMaskOKClick_watcher } from 'src/redux/actions/beamformers/risAction'

// import { RotatingLines } from 'react-loader-spinner'
import useGetUIControl from 'src/hooks/useGetUIControl'
import CommonButton from 'src/components/Button/CommonButton'

const OverlayMask = ({ sn }) => {
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------

  const { all: all_uiControl, single: s_uiControl } = useGetUIControl(sn)

  const {
    showFirst3DInstructionMask,
    showManual3DInstructionMask,
    showImportModeMask,
  } = s_uiControl.deviceControl
  const { dontShowAgainForSteeringMask } = all_uiControl
  const { instruction: dontShowInstructionMask } = dontShowAgainForSteeringMask

  const showManualInstructionMask = showManual3DInstructionMask
  const showFirstInstructionMask =
    !dontShowInstructionMask && showFirst3DInstructionMask

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  if (showImportModeMask) return <ImportModeMask sn={sn} />

  if (showManualInstructionMask || showFirstInstructionMask)
    return (
      <InstructionMask
        {...{
          sn,
          showFirstInstructionMask,
          showManual3DInstructionMask,
        }}
      />
    )

  return <></>
}

OverlayMask.propTypes = { sn: PropTypes.string }

export default OverlayMask

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Static CSS     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const container = `
                  w-full h-full
                  p-8
                  flex flex-col
                  items-center justify-center
                  text-base font-normal leading-5
                  text-white text-center
                  `

const messageContainer = `max-w-[345px] mx-auto text-white text-center`

const h6 = `text-light-4 text-base font-bold text-center`
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* ----------------- Another Components -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// const LoadingMask = () => (
//   <Mask className='z-20 rounded-lg'>
//     <div className='w-full h-full flex flex-col items-center justify-center gap-y-10'>
//       <RotatingLines
//         strokeColor='white'
//         strokeWidth='3'
//         animationDuration='0.75'
//         width='56'
//         visible={true}
//       />
//       <h6 className={h6}>Synchronizing changes</h6>
//     </div>
//   </Mask>
// )

const ImportModeMask = ({ sn, dontShowCHModeMask }) => {
  const dispatch = useDispatch()

  const handleOKClick = () =>
    dispatch(ris_steering_importModeMaskOKClick_watcher({ sn }))

  return (
    <Mask className='z-20 rounded-lg'>
      <div className={container}>
        <h6 className={h6}>Synchronizing changes</h6>

        <div className={messageContainer}>
          Manually imported files will not be simulated
        </div>

        <br />

        <div>Your pattern will be overwritten.</div>

        <CommonButton
          size='md'
          type='general'
          className='mt-10 mb-[130px]'
          onClick={() => handleOKClick(false)}>
          OK
        </CommonButton>
      </div>
    </Mask>
  )
}
