import React from 'react'
import SegmentedButton from 'src/components/Button/SegmentedButton'

const TargetBeamControl = ({
  targetBeam,
  thetaI,
  thetaR,
  handleTargetBeamChange,
}) => (
  <SegmentedButton
    size='sm'
    type='outlined' // 'outlined' : 'solid'
    className='absolute top-6 inset-x-0 mx-[75px] z-10'
    value={targetBeam === 'distance' ? 'incident_0' : targetBeam}
    onChange={(event, value) => handleTargetBeamChange(value)}>
    {thetaI.map((e, i, a) => (
      <SegmentedButton.Option
        key={`target beam button incident_${i}`}
        name={`incident_${i}`}>
        Incident {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
      </SegmentedButton.Option>
    ))}

    {thetaR.map((e, i, a) => (
      <SegmentedButton.Option
        key={`target beam button reflection${i}`}
        name={`reflection_${i}`}>
        Reflection {a.length >= 2 && <span className='ml-1'>{i + 1}</span>}
      </SegmentedButton.Option>
    ))}
  </SegmentedButton>
)

export default TargetBeamControl
