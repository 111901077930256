import { mockChannel_default } from '../../constants/ris.mjs'
import { getDefaultCurrentFreq } from '../../configs/spec.mjs'
import Controllers from '../../Controllers/index.mjs'

const getDT = sn => Controllers._getDeviceType(sn)

export const risInitAppendData = sn => ({
  deviceControl: {
    common: {
      currentFreq: getDefaultCurrentFreq[getDT(sn)],
    },
    steering: {
      incident: new Array(1).fill(null).map((e, i) => ({
        index: i,
        theta: 0,
        thetaMax: 60, // 沒天線所以要先定義
        phi: 0,
        distance: 0,
        distanceMin: 0,
        distanceMax: 500,
      })),
      reflection: new Array(1).fill(null).map((e, i) => ({
        index: i,
        theta: 0,
        thetaMax: 60, // 沒天線所以要先定義
        phi: 0,
      })),
    },
    channel: mockChannel_default,
  },
})
