import { createSlice, current } from '@reduxjs/toolkit'
import * as R from 'ramda'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Single        -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const itemData = {
  common: { refSource: { isRequesting: false, isSuccess: false } },
}

export const cclCttcUIControlForSingleSlice = createSlice({
  name: 'cclCttcUIControlForSingle',
  initialState: {},
  reducers: {
    addUiControl(state, action) {
      const { sn } = action.payload
      return { ...current(state), [sn]: itemData }
    },

    // refSource
    clearRefSourceStatus(state, action) {
      const { sn } = action.payload
      state[sn].common.refSource = R.map(R.always(false))(
        state[sn].common.refSource
      )
      return state
    },
    setRefSourceIsRequesting(state, action) {
      const { sn } = action.payload
      state[sn].common.refSource.isRequesting = true
      state[sn].common.refSource.isSuccess = false
      return state
    },
    setRefSourceIsSuccess(state, action) {
      const { sn } = action.payload
      state[sn].common.refSource.isRequesting = false
      state[sn].common.refSource.isSuccess = true
      return state
    },
  },
})

export const { addUiControl } = cclCttcUIControlForSingleSlice.actions

export const cclCttcUIControlForSingleActions =
  cclCttcUIControlForSingleSlice.actions

export const cclCttcUIControlForSingle = cclCttcUIControlForSingleSlice.reducer
