import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ErrorBoundaryOfDevice from 'src/containers/ErrorBoundary/Device'

import BeamSteering from 'src/containers/operating/device/beamformers/ris/BeamSteering'
import Channel from 'src/containers/operating/device/beamformers/ris/Channel'

import DeviceInitializing from 'src/containers/operating/DeviceInitializing'
import DeviceSomethingWereWrong from 'src/containers/operating/DeviceSomethingWereWrong'

import { beamformers_initDevice_watcher } from 'src/redux/actions/beamformers/generalAction'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetScreenSize from 'src/hooks/useGetScreenSize'
import useGetHelmet from 'src/hooks/useGetHelmet'

import operational_failure from 'src/assets/img/operational_failure.png'
import Common from 'src/containers/operating/device/beamformers/ris/Common'

const RIS = () => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const info = current.data.info

  const { isMobile } = useGetScreenSize()

  useEffect(() => {
    if (info?.initialization === 'unfinished')
      dispatch(beamformers_initDevice_watcher({ sn: sn }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sn])

  // mobile 不支援，所以直接 break
  if (isMobile) return <NotSupportMobile />

  if (info?.initialization === 'unfinished') return <DeviceInitializing />
  if (info?.initialization === 'failed')
    return (
      <DeviceSomethingWereWrong
        handleRetry={() => dispatch(beamformers_initDevice_watcher({ sn }))}
      />
    )

  return (
    <section className={'flex flex-col xl:flex-row items-start gap-4'}>
      <div className='max-w-[632px] w-full '>
        <div className='functionBlockBackground py-3 px-5 rounded-md mb-4'>
          <Common />
        </div>

        <div className='functionBlockBackground p-4 rounded-md'>
          <BeamSteering />
        </div>
      </div>

      <div className='functionBlockBackground p-4 rounded-md max-w-[632px] xl:max-w-[416px] w-full '>
        <Channel />
      </div>
    </section>
  )
}

const RISWithErrorBoundary = () => {
  const { current } = useGetCurrentDeviceData()
  const deviceType = current.deviceType
  const helmet = useGetHelmet({ deviceType })

  return (
    // TODO: retry 補 call re-init api
    <ErrorBoundaryOfDevice
      handleRetry={() => console.log('Error Boundary Retry Click')}>
      {helmet}
      <RIS />
    </ErrorBoundaryOfDevice>
  )
}

export default RISWithErrorBoundary

const NotSupportMobile = () => (
  <section className='w-full h-[515px] bg-light-4/10 flex flex-col justify-center items-center gap-y-10'>
    <div className='w-[240px] h-[188px]'>
      <img src={operational_failure} alt='TMYTEK operational_failure' />
    </div>

    <div className='w-[288px] text-light-4 font-normal text-base leading-5 text-center'>
      Mobile controls are not supported. Please use the desktop computer to
      continue.
    </div>
  </section>
)
