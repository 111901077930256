import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import BeamSteeringModuleForRIS from 'src/components/composite/beamformers/BeamSteeringModule/ForRIS'
import OverlayMask from 'src/containers/operating/device/beamformers/ris/BeamSteering/OverlayMask'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import {
  ris_steering_thetaPhiChange_watcher,
  ris_steering_3DCameraChange_watcher,
  ris_steering_distanceChange_watcher,
} from 'src/redux/actions/beamformers/risAction'

import {
  change3DDirection,
  setShowManual3DInstructionMask,
} from 'src/redux/slices/uiControl/beamformers/ris'
import useGetUIControl from 'src/hooks/useGetUIControl'

import {
  getBeamIndex,
  getBeamName,
  isIncident,
  isReflection,
} from 'src/funcs/device/ris'

const BeamSteering = props => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn
  const deviceType = current.data.info.deviceType

  const [targetBeam, setTargetBeam] = useState('incident_0')

  const pickValue = targetBeam =>
    current.data.deviceControl.steering[targetBeam]

  const pickI = pickValue('incident')
  const pickR = pickValue('reflection')

  const [thetaI, setThetaI] = useState(pickI.map(e => e['theta'] || [0]))
  const [phiI, setPhiI] = useState(pickI.map(e => e['phi']) || [0])

  const [thetaR, setThetaR] = useState(pickR.map(e => e['theta']) || [0])
  const [phiR, setPhiR] = useState(pickR.map(e => e['phi']) || [0])

  const thetaMaxI = pickI.map(e => e['thetaMax'])
  const thetaMaxR = pickR.map(e => e['thetaMax'])

  const distance = pickI.map(e => e['distance'])
  const distanceMin = pickI.map(e => e['distanceMin'])
  const distanceMax = pickI.map(e => e['distanceMax'])

  const { single: s_uiControl } = useGetUIControl(sn)

  const deviceDirection = s_uiControl?.deviceControl.deviceDirection
  const beam3DCameraArgs = s_uiControl?.deviceControl.beam3DCameraArgs

  // //* ----------------- ------------------ -----------------
  // //* ----------------- ------------------ -----------------
  // //* -----------------     Life Cycle     -----------------
  // //* ----------------- ------------------ -----------------
  // //* ----------------- ------------------ -----------------
  useEffect(() => {
    setThetaI(pickI.map(e => e['theta']))
    setPhiI(pickI.map(e => e['phi']))
    setThetaR(pickR.map(e => e['theta']))
    setPhiR(pickR.map(e => e['phi']))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickI, pickR])

  // //* ----------------- ------------------ -----------------
  // //* ----------------- ------------------ -----------------
  // //* -----------------       Event        -----------------
  // //* ----------------- ------------------ -----------------
  // //* ----------------- ------------------ -----------------
  const handleDrag = ({ resultTheta, resultPhi, targetBeam: _targetBeam }) => {
    const targetIndex = getBeamIndex(_targetBeam)

    const arrayUpdate = newValue => prev =>
      prev.map((e, i) => (i === +targetIndex ? newValue : e))

    if (isReflection(_targetBeam)) {
      setThetaR(arrayUpdate(resultTheta))
      setPhiR(arrayUpdate(resultPhi))
    }

    if (isIncident(_targetBeam)) {
      setThetaI(arrayUpdate(resultTheta))
      setPhiI(arrayUpdate(resultPhi))
    }
  }

  const handleDragEnd = e =>
    dispatch(
      ris_steering_thetaPhiChange_watcher({
        sn,
        targetBeam,
        theta: isIncident(targetBeam)
          ? thetaI[getBeamIndex(targetBeam)]
          : thetaR[getBeamIndex(targetBeam)],
        phi: isIncident(targetBeam)
          ? phiI[getBeamIndex(targetBeam)]
          : phiR[getBeamIndex(targetBeam)],
        isValid: true,
      })
    )

  const handleCameraChange = ({ position, rotation }) =>
    dispatch(
      ris_steering_3DCameraChange_watcher({
        sn,
        position,
        rotation,
      })
    )

  const handleDirectionChange = value => {
    dispatch(change3DDirection({ sn, value }))
  }

  const handleInputFocus = ({ targetBeam }) => {
    setTargetBeam(targetBeam)
  }

  const handleInputChange = ({
    targetBeam: _targetBeam,
    label,
    value,
    isValid,
  }) => {
    const beamName = getBeamName(_targetBeam)
    const beamIndex = getBeamIndex(_targetBeam)

    let theta =
      label === 'theta'
        ? value
        : current.data.deviceControl.steering[beamName][beamIndex].theta
    let phi =
      label === 'phi'
        ? value
        : current.data.deviceControl.steering[beamName][beamIndex].phi

    dispatch(
      ris_steering_thetaPhiChange_watcher({
        sn,
        targetBeam: _targetBeam,
        theta,
        phi,
        isValid,
      })
    )
  }

  const handleDistanceChange = ({ value, index, isValid }) =>
    dispatch(
      ris_steering_distanceChange_watcher({
        sn,
        index,
        value,
        isValid,
      })
    )

  const handleHelpButtonClick = () =>
    dispatch(setShowManual3DInstructionMask({ sn, value: true }))

  const handleBoresightClick = () =>
    dispatch(
      ris_steering_thetaPhiChange_watcher({
        sn,
        targetBeam,
        theta: 0,
        phi: 0,
        isValid: true,
      })
    )

  const beamSteeringProps = {
    sn,
    isDontRender3D: false,
    deviceType,
    targetBeam,
    handleTargetBeamChange: value => setTargetBeam(value),

    // value
    thetaI,
    thetaR,
    phiI,
    phiR,
    thetaMaxI,
    thetaMaxR,
    distance,
    distanceMin,
    distanceMax,

    // 3d control event
    handleDrag,
    handleDragEnd,
    beam3DCameraArgs,
    handleCameraChange,

    // 3d button event
    deviceDirection,
    handleDirectionChange,
    handleHelpButtonClick,
    handleBoresightClick,

    // input event
    handleInputChange,
    handleInputFocus,
    handleDistanceChange,
  }

  return (
    <div className='relative rounded-md'>
      <OverlayMask sn={sn} />
      <BeamSteeringModuleForRIS {...beamSteeringProps} />
    </div>
  )
}

export default BeamSteering
